<template>
    <CustomDialog ref="dialog" :title="data_file.titulo" @accept="upload" @cancel="cancel">
        <v-form ref="form" id="form">
            <v-row>
                <v-col cols="12" v-if="!processing">
                    <v-label>Archivo</v-label>
                    <v-file-input :rules="[rules.required]" v-model="item.archivo" :label="data_file.file_imput_label" :accept="data_file.acepted_file_type" solo outlined dense flat hide-details @change="set_name" />
                </v-col>
                <v-col cols="12" v-if="!processing">
                    <v-label>{{ data_file.name }}</v-label>
                    <v-text-field :rules="[rules.required]" hide-details solo outlined dense flat v-model="item.nombre" placeholder="Nombre" />
                </v-col>
                <v-col cols="12" v-else>
                    <v-label>Subiendo {{ item.nombre }}</v-label>
                    <v-progress-linear :value="progress" striped rounded height="32" color="secondary">
                        <template v-slot="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-col>
            </v-row>
        </v-form>
        <Message ref="success" @onClose="close" />
    </CustomDialog>
</template>

<script>
import EmptyCard from '@/shared/components/EmptyCard'
import Message from '@/shared/components/Message'
import CustomDialog from '@/shared/components/CustomDialog'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        CustomDialog,
        Message,
        EmptyCard
    },
    props: {
        data_file: {
            type: Object,
            required: true,
            titulo: String,
            file_imput_label: String,
            acepted_file_type: String,
            name: String,
            url_upload: String,
            message_success: String
        },
        // para cuando se sube un archivo que es un logo de empresa
        empresa: { 
            type: Number
        }
    },
    data: () => ({
        list: [],
        item: {},
        processing: false,
        progress: 0,
        rules: {
            required: valid_required
        }
    }),
    methods: {
        show(item) {
            this.$refs.dialog.show();
            this.$refs.dialog.persistent = false;
            this.$refs.dialog.actions_enabled = true;
        },
        set_name(file) {
            if(!file) return;
            this.item.nombre = file.name;
        },
        upload() {
            if(!this.$refs.form.validate()) {
                this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            if(!this.item.archivo) {
                this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], "Debe especificar un archivo.", "error");
                return;
            }

            this.item.nombre = this.item.nombre || "Presentación sin título";
            this.processing = true;
            this.$refs.dialog.persistent = true;
            this.$refs.dialog.actions_enabled = false;

            var form_data = new FormData();
            form_data.append('nombre', this.item.nombre);
            form_data.append('archivo', this.item.archivo);
            if(!!this.empresa){
                form_data.append('empresa', this.empresa);
            }
            let options = { onUploadProgress: progressEvent => this.progress = (progressEvent.loaded / progressEvent.total) * 100 };
            const url = this.data_file.url_upload ;
            axiosHelper.post(url, form_data, { 'content-type': 'multipart/form-data' }, options)
                .then(response => {
                    this.$refs.success.show(messages.titles["success_generico"], this.data_file.message_success, "success");
                })
                .catch(error => {
                    this.processing = false;
                    this.$refs.dialog.persistent = false;
                    this.$refs.dialog.actions_enabled = true;
                    this.$refs.dialog.$refs.message.show(messages.titles["error_generico"], messages.texts["error_generico"], "error");
                });
        },
        close() {
            this.$refs.dialog.hide();
            this.$emit('success');
            this.processing = false;
        },
        cancel() {
            if(!!this.$refs.form) this.$refs.form.reset();
            this.item = {};
            this.processing = false;
            this.$refs.dialog.hide();
        }
    }
}
</script>