<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <h2 class="subtitle-1 font-weight-regular">Logos empresas</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col v-for="empresa in empresas" :key="empresa.id" cols="12" md="6" lg="4">
                            <v-card class="logo">
                                <v-img :src="empresa.logo_name"></v-img>
                                <v-card-title>
                                    {{ empresa.nombre }}
                                </v-card-title>
                                <!-- Acciones (botones) -->
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <!-- Botón para editar -->
                                    <v-btn icon @click="upload(empresa)">
                                        <v-icon>create</v-icon>
                                    </v-btn>
                                    <!-- Botón para eliminar -->
                                    <!-- <v-btn icon @click="deleteEmpresa(empresa)">
                                        <v-icon>delete</v-icon>
                                    </v-btn> -->
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <Message ref="message" />
        <DialogUploadPresentacion ref="upload" :data_file="data_file" :empresa="selected_empresa_id" @success="recargar_este_componente()"/>
    </v-card>
</template>

<style scoped>
    .logo {
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 100%);
        text-shadow: 0px -1px rgba(0,0,0,0.125), 0px 1px rgba(255,255,255,0.25);
    }
</style>

<script>
    import DashCard from '@/shared/components/DashCard'
    import Message from '@/shared/components/Message'
    import DialogUploadPresentacion from '@/educap/dialogs/DialogUploadPresentacion'
    import { axiosHelper } from '@/helpers'
    export default {
        components: {
            DashCard,
            Message,
            DialogUploadPresentacion
        },
        data: () => ({
            empresas: [],
            selected_empresa_id:null,
            data_file: {
                titulo: 'Subir logo de empresa u organización',
                file_imput_label: 'Archivos jpg, png (*.jpg, *.png)',
                acepted_file_type: '.jpg, .png',
                name: 'Nombre del archivo',
                url_upload: 'edu/logos/',
                message_success: 'Archivo subido correctamente'
            }
        }),
        // hooks
        created() {
            this.load_data()
        },
        // methods
        methods: {
            async load_data(){
                //
                try {
                    const response = await axiosHelper.get('edu/empresas-logos');
                    let data = response.data;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].logo_name){
                            data[i].logo_name = process.env.VUE_APP_API_URL + 'media/' + data[i].logo_name;
                        }
                        else{
                            data[i].logo_name = process.env.VUE_APP_API_URL + 'media/logos/logo_default.png';
                        }
                    }
                    this.empresas = data;
                } catch (error) {
                    this.$refs.message.show(error.message, 'error')
                }
            },
            upload(empresa) {
                //
                this.selected_empresa_id = parseInt(empresa.id);
                this.$refs.upload.show();
            },
            recargar_este_componente(){
                this.load_data();
            },
            deleteEmpresa(empresa) {
                //
                axiosHelper.del('edu/logo-empresas/' + empresa.id + '/')
                    .then(response => this.load_data())
                    .catch(response => this.$refs.message.show(response.message, 'error'))
            }
        } 
    }
</script>

