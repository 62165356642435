<template>
    <div>
        <v-app-bar color="white" app flat>
            <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon @click="show_sidebar">
                <v-icon>menu</v-icon>
            </v-btn>
            <v-icon large color="secondary" v-if="!!title.icon">{{ title.icon }}</v-icon>
            <h1 class="title font-weight-regular pa-2 window-title">{{ title.text }}</h1>
        </v-app-bar>
        <v-row>
            <v-col cols="12">
                <DashCard title="Configuraciones" title-icon="build" body-class="pa-0">
                    <LogoEmpresas />
                </DashCard>
            </v-col>
        </v-row>
    </div>
    
</template>

<script>
    import DashCard from '@/shared/components/DashCard'
    import LogoEmpresas from '@/educap/components/configAdmin/LogoEmpresas.vue'

    export default {
        components: {
            DashCard,
            LogoEmpresas
        },
        data: () => ({
            title: {
                icon: "build",
                text: "Configuraciones del administrador"
            }
        }),
        methods: {
            recargar_componente(){
                this.$router.go(0);
            }
        }

    }
</script>
